<template>
  <div class="payList">
    <el-container id="el-container">
      <!-- 页面主题 -->
      <my-header></my-header>
      <el-main class="el-main">
        <div>
          <el-alert
            class="el-alert"
            title="填写并核对订单信息。"
            type="info"
            show-icon
          >
          </el-alert>
          <!-- 收货人信息 -->
          <el-row class="el-row-1">
            <el-col :span="6">收货人信息</el-col>
            <el-col :span="14">
              <span @click="clickAddsite">添加收货地址</span>
            </el-col>
          </el-row>
          <el-row class="el-row-2">
            <el-col :span="24" v-if="addressList[siteKey]">
              <div v-if="!moreSite" class="el-row-2-span-v-if">
                <span
                  class="el-row-2-span"
                  v-text="
                    `${addressList[siteKey].receiverName}  ${addressList[siteKey].receiverProvince}  ${addressList[siteKey].receiverCity}  ${addressList[siteKey].receiverDistrict}  ${addressList[siteKey].receiverAddress}  ${addressList[siteKey].receiverMobile}  ${addressList[siteKey].receiverZip}`
                  "
                >
                </span>
                <span class="el-row-2-span-option">
                  <i
                    class="el-icon-delete el-row-2-span-option"
                    @click="delSite(siteKey)"
                  ></i>
                  <i
                    class="el-icon-edit-outline el-row-2-span-option"
                    @click="clickupdateSite(siteKey)"
                  ></i>
                </span>
              </div>
              <div v-else>
                <div
                  v-for="(val, key) of addressList"
                  :key="key"
                  class="el-row-2-span-v-if"
                >
                  <span
                    class="el-row-2-span el-row-2-span-v-for"
                    @click="selectSity(key)"
                    v-text="
                      `${addressList[key].receiverName}  ${addressList[key].receiverProvince}  ${addressList[key].receiverCity}  ${addressList[key].receiverDistrict}  ${addressList[key].receiverAddress}  ${addressList[key].receiverMobile}  ${addressList[key].receiverZip}`
                    "
                  >
                  </span>
                  <span class="el-row-2-span-option">
                    <i
                      class="el-icon-delete el-row-2-span-option"
                      @click="delSite(key)"
                    ></i>
                    <i
                      class="el-icon-edit-outline el-row-2-span-option"
                      @click="clickupdateSite(key)"
                    ></i>
                  </span>
                </div>
              </div>
              <div @click="moreSite = !moreSite">
                <span
                  v-text="moreSite ? '收起地址' : '更多地址'"
                  class="moreSite"
                >
                </span
                ><i
                  :class="moreSite ? 'el-icon-arrow-up' : 'el-icon-arrow-down'"
                ></i>
              </div>
            </el-col>
          </el-row>
          <!-- 收货人信息结束 -->
          <!-- 商品详情开始 -->
          <el-row>
            <span class="goback-shop">
              <router-link to="goodCar"> 返回修改购物订单 </router-link>
            </span>
          </el-row>
          <div class="paylist-box" v-for="(val, key) of payList" :key="key">
            <el-row class="el-row-3">
              <el-col :span="3">
                <el-image class="el-image-1" :src="val.productImg"></el-image>
              </el-col>
              <el-col :span="9">
                <span>{{ val.productName }}</span>
              </el-col>
              <el-col :span="4">
                <p>
                  <span>￥</span>
                  <span>{{ val.unitPrice }}</span>
                  <span>.00</span>
                </p>
              </el-col>
              <el-col :span="4">
                <span>x</span>
                <span>{{ val.productNum }}</span>
              </el-col>
              <el-col :span="4">
                <span>有货</span>
              </el-col>
            </el-row>
          </div>
          <!-- 商品详情结束 -->
          <el-row>
            <el-col class="total-price">
              <span>{{ payList.length }}</span>
              <span>件商品,总商品金额:</span>
              <span v-text="`￥${orderId}.00`"></span>
            </el-col>
          </el-row>
          <!-- 订单信息 -->
          <el-row class="order-form">
            <el-col>
              <span>应付总额</span>
              <span v-text="`￥${orderId}.00`"></span>
            </el-col>
            <el-col v-if="addressList[siteKey]">
              <span
                v-text="
                  `${addressList[siteKey].receiverName}  ${addressList[siteKey].receiverProvince}  ${addressList[siteKey].receiverCity}  ${addressList[siteKey].receiverDistrict}  ${addressList[siteKey].receiverAddress}  ${addressList[siteKey].receiverMobile}  ${addressList[siteKey].receiverZip}`
                "
              >
              </span>
            </el-col>
          </el-row>
          <div class="submit-order" @click="submitOrder">
            <span>提交订单</span>
          </div>
          <!-- 订单信息结束 -->
        </div>
      </el-main>
      <!-- 页面主题结束 -->
      <!-- 添加地址模块 -->
      <div class="addsite" v-if="addsite">
        <span>添加地址</span>
        <i class="el-icon-close" @click="addsite = false"></i>
        <el-form
          :model="ruleForm"
          status-icon
          :rules="rules"
          ref="ruleForm"
          label-width="100px"
          class="demo-ruleForm"
        >
          <el-form-item label="姓名" prop="receiverName">
            <el-input
              type="text"
              v-model="ruleForm.receiverName"
              autocomplete="off"
            >
              ></el-input
            >
          </el-form-item>
          <el-form-item label="手机号" prop="receiverMobile">
            <el-input v-model.number="ruleForm.receiverMobile"></el-input>
          </el-form-item>
          <!-- <el-form-item label="省份" prop="receiverProvince">
            <el-input
              type="text"
              v-model="ruleForm.receiverProvince"
              autocomplete="off"
            ></el-input>
          </el-form-item> -->
          <!-- 省市区 -->
          <el-form-item label="省市区" prop="receiverProvince">
            <el-cascader
              size="medium"
              :options="options"
              v-model="ruleForm.receiverProvince"
              @change="handleChange"
            >
            </el-cascader>
          </el-form-item>
          <!-- 详细地址 -->
          <el-form-item label="详细地址" prop="receiverAddress">
            <el-input v-model="ruleForm.receiverAddress"></el-input>
          </el-form-item>
          <!-- 邮编 -->
          <el-form-item label="邮编" prop="receiverZip">
            <el-input v-model.number="ruleForm.receiverZip"></el-input>
          </el-form-item>
          <el-form-item>
            <el-button type="primary" @click="submitForm('ruleForm')"
              >提交</el-button
            >
            <el-button @click="resetForm('ruleForm')">重置</el-button>
          </el-form-item>
        </el-form>
      </div>
      <!-- 添加地址模块结束 -->

      <!-- 修改地址模块 -->
      <div class="addsite" v-if="upsite">
        <span>修改地址</span>
        <i class="el-icon-close" @click="upsite = false"></i>
        <el-form
          :model="ruleForm"
          status-icon
          :rules="rules"
          ref="ruleForm"
          label-width="100px"
          class="demo-ruleForm"
        >
          <el-form-item label="姓名" prop="receiverName">
            <el-input
              type="text"
              v-model="ruleForm.receiverName"
              autocomplete="off"
            >
              ></el-input
            >
          </el-form-item>
          <el-form-item label="手机号" prop="receiverMobile">
            <el-input v-model.number="ruleForm.receiverMobile"></el-input>
          </el-form-item>
          <!-- <el-form-item label="省份" prop="receiverProvince">
            <el-input
              type="text"
              v-model="ruleForm.receiverProvince"
              autocomplete="off"
            ></el-input>
          </el-form-item> -->
          <!-- 省市区 -->
          <el-form-item label="省市区" prop="receiverProvince">
            <el-cascader
              size="medium"
              :options="options"
              v-model="ruleForm.receiverProvince"
              @change="handleChange"
            >
            </el-cascader>
          </el-form-item>
          <!-- 详细地址 -->
          <el-form-item label="详细地址" prop="receiverAddress">
            <el-input v-model="ruleForm.receiverAddress"></el-input>
          </el-form-item>
          <!-- 邮编 -->
          <el-form-item label="邮编" prop="receiverZip">
            <el-input v-model.number="ruleForm.receiverZip"></el-input>
          </el-form-item>
          <el-form-item>
            <el-button type="primary" @click="updateSite('ruleForm')"
              >提交</el-button
            >
            <el-button @click="resetForm('ruleForm')">重置</el-button>
          </el-form-item>
        </el-form>
      </div>
      <!-- 修改地址模块结束 -->
    </el-container>
  </div>
</template>

<script>
import {
  provinceAndCityData,
  regionData,
  provinceAndCityDataPlus,
  regionDataPlus,
  CodeToText,
  TextToCode,
} from "element-china-area-data";
import { mapState } from 'vuex';
export default {
  computed:{
    ...mapState(['companyId'])
  },
  data() {
    var userMobile = (rule, value, callback) => {
      if (!value) {
        return callback(new Error("手机号不能为空"));
      }
      setTimeout(() => {
        if (!Number.isInteger(value)) {
          callback(new Error("请输入数字值"));
        } else {
          callback();
        }
      }, 1000);
    };
    var username = (rule, value, callback) => {
      if (value === "") {
        callback(new Error("请输入姓名"));
      } else {
        callback();
      }
    };
    var userprovince = (rule, value, callback) => {
      if (value === "") {
        callback(new Error("请选择省市区信息"));
      } else {
        callback();
      }
    };
    var userAddress = (rule, value, callback) => {
      if (value === "") {
        callback(new Error("请输入详细地址"));
      } else {
        callback();
      }
    };
    var userZip = (rule, value, callback) => {
      if (!value) {
        return callback(new Error("邮编不能为空"));
      }
      setTimeout(() => {
        if (!Number.isInteger(value)) {
          callback(new Error("请输入数字值"));
        } else {
          callback();
        }
      }, 1000);
    };
    return {
      // 购买方式
      payState: 1,
      // 修改用户地址id
      updataid: "",
      // 更多地址收起展开
      moreSite: false,
      // 当前地址下标
      siteKey: 0,
      // 省市区
      options: regionData,
      // 修改地址模块显示状态
      upsite: false,
      // 添加地址模块显示状态
      addsite: false,
      // companyId:"",
      ruleForm: {
        receiverName: "", //姓名
        receiverProvince: "", //省市区
        receiverMobile: "", //手机
        receiverAddress: "", //详细地址
        receiverZip: "", //邮编
      },
      rules: {
        receiverName: [{ validator: username, trigger: "blur" }],
        receiverProvince: [{ validator: userprovince, trigger: "blur" }],
        receiverMobile: [{ validator: userMobile, trigger: "blur" }],
        receiverAddress: [{ validator: userAddress, trigger: "blur" }],
        receiverZip: [{ validator: userZip, trigger: "blur" }],
      },
      // 添加地址模块显示状态
      payList: [],
      dialogAFormVisible: false,
      addressList: [
        {
          receiverName: "", //String     收货姓名
          receiverMobile: "", //String     收货手机号
          receiverProvince: "", //String     省地址
          receiverCity: "", //String     市地址
          receiverDistrict: "", //String     县区地址
          receiverAddress: "", //String     详细地址
          receiverZip: "", //String     邮编
        },
      ],
      dialogFormVisible: false,
      form: {
        receiverName: "", //String     收货姓名
        receiverMobile: "", //String     收货手机号
        receiverProvince: "", //String     省地址
        receiverCity: "", //String     市地址
        receiverDistrict: "", //String     县区地址
        receiverAddress: "", //String     详细地址
        receiverZip: "", //String     邮编
        orderItem: "", //返回订单id
      },
      formLabelWidth: "120px",
      orderId: "",
    };
  },
  methods: {
    handleChange(value) {
      console.log(value);
    },
    // 保存订单
    async submitOrder() {
      let siteKey = this.siteKey;
      let addressList = this.addressList;
      console.log(addressList[siteKey]);
      console.log(this.payState);
      const res = await this.$http({
        method: "post",
        url: `front/order/submitOrder`,
        data: {
          shippingId: addressList[siteKey].id,
          payState: this.payState,
          type:'1',
          companyId:this.companyId,
        },
      });
      console.log(res.data);
      if (res.data.status == 200) {
        this.$message({
          message: "提交成功",
          type: "success",
        });
        this.$router.push("/orderList");
      } else {
        console.log("提交失败");
      }
    },
    // 修改地址
    clickupdateSite(key) {
      this.upsite = !this.upsite;
      console.log(this.addressList[key]);
      const data = JSON.stringify(this.addressList[key]);
      this.ruleForm = JSON.parse(data);
      this.updataid = this.addressList[key].id;
    },
    async updateSite() {
      console.log(this.updataid);
      const res = await this.$http({
        method: "post",
        url: "front/shipping/update",
        data: {
          id: this.updataid,
          receiverName: this.ruleForm.receiverName,
          receiverMobile: this.ruleForm.receiverMobile,
          receiverProvince: CodeToText[this.ruleForm.receiverProvince[0]],
          receiverCity: CodeToText[this.ruleForm.receiverProvince[1]],
          receiverDistrict: CodeToText[this.ruleForm.receiverProvince[2]],
          receiverAddress: this.ruleForm.receiverAddress,
          receiverZip: this.ruleForm.receiverZip,
        },
      });
      this.dialogAFormVisible = false;
      if (res.data.status == 200) {
        this.$message({
          message: "修改成功！",
          type: "success",
        });
        this.getAddress();
        this.upsite = !this.upsite;
      } else {
        this.$message.error("修改失败");
      }
    },
    // 选择地址
    selectSity(key) {
      this.siteKey = key;
      this.moreSite = false;
      this.getAddress();
    },
    // 添加地址模块
    submitForm(formName) {
      this.$refs[formName].validate(async (valid) => {
        const ruleForm = this.ruleForm;
        const res = await this.$http({
          method: "post",
          url: `front/shipping/insert`,
          data: {
            receiverName: ruleForm.receiverName,
            receiverMobile: ruleForm.receiverMobile,
            receiverProvince: CodeToText[ruleForm.receiverProvince[0]],
            receiverCity: CodeToText[ruleForm.receiverProvince[1]],
            receiverDistrict: CodeToText[ruleForm.receiverProvince[2]],
            receiverAddress: ruleForm.receiverAddress,
            receiverZip: ruleForm.receiverZip,
          },
        });
        console.log(res.data.status);
        if (res.data.status == 200) {
          this.$message({
            message: "添加成功！",
            type: "success",
          });
          this.getAddress();
          this.addsite = !this.addsite;
        } else {
          this.$message.error("添加失败");
        }
      });
    },
    clickAddsite() {
      this.addsite = !this.addsite;
      this.ruleForm = {};
    },
    resetForm(formName) {
      this.$refs[formName].resetFields();
    },
    // 接受订单参数
    getRouteData() {
      this.payList = JSON.parse(this.$route.query.name);
      this.orderId = JSON.parse(this.$route.query.host);
      console.log(this.orderId);
      console.log(this.payList);
    },
    // 获取购物车信息
    async getGoodShop() {
      const res = await this.$http({
        method: "get",
        url: "front/cart/findList",
      });
      if (res.data.data) {
        this.$store.commit(
          "altershopnum",
          res.data.data.cartProductVoList.length
        );
      }
    },
    // 获取地址信息
    async getAddress() {
      const res = await this.$http({
        method: "get",
        url: "front/shipping/findList",
      });
      this.addressList = res.data.data.list;
      console.log(this.addressList);
    },
    // 删除地址
    async delSite(key) {
      const res = await this.$http({
        method: "post",
        url: `front/shipping/delete`,
        data: {
          id: this.addressList[key].id,
        },
      });
      if (res.data.status == 200) {
        this.$message({
          message: "删除成功",
          type: "success",
        });
      } else {
        this.$message.error("删除失败");
      }
      this.getAddress();
    },
  },
  created() {
    this.getRouteData();
    this.getAddress();
    this.getGoodShop();
  },
};
</script>

<style scoped>
* {
  margin: 0;
  padding: 0;
  /* width: 100%; */
}
body {
  width: 100%;
}
.payList {
  width: 960px;
  margin: 0 auto;
}

#el-container {
  /* background-color: #f4f4f4; */
  /* height: 8000px; */
  margin: 0;
  padding: 0;
}
.el-main {
  width: 100%;
  background: #fff;
  border: 1px solid #ddd;
  margin-bottom: 50px;
  margin-top: 200px;
  border-radius: 15px;
  padding: 30px;
  overflow: visible;
}
.el-alert {
  padding: 15px;
}
.el-row-1 {
  display: flex;
  justify-content: space-between;
  padding: 10px 0;
}
.el-row-1 .el-col:nth-child(1) {
  font-weight: 700;
}
.el-row-1 .el-col:nth-child(3) {
  text-align: right;
  margin-right: 35px;
  font-size: 14px;
}
.el-row-1 .el-col:nth-child(2) {
  margin-left: 30px;
  font-size: 14px;
}
.el-row-1 .el-col:nth-child(2) span,
.el-row-1 .el-col:nth-child(3) span {
  float: right;
  color: #0d65a7;
  user-select: none;
  cursor: pointer;
}
.el-row-1 .el-col:nth-child(2) span:hover,
.el-row-1 .el-col:nth-child(3) span:hover {
  color: #f30213;
}
.el-row-2 {
  padding: 20px;
  padding-bottom: 30px;
  text-indent: 20px;
  border-bottom: 1px solid #ddd;
}
.goback-shop a {
  float: right;
  color: #0d65a7;
  user-select: none;
  cursor: pointer;
  padding: 15px;
  padding-right: 30px;
  font-size: 14px;
  user-select: none;
  cursor: pointer;
}
.goback-shop a:hover {
  color: #f30213;
}
.el-image-1 {
  width: 100%;
  height: 105px;
  border: 1px solid #eee;
  border-radius: 4px;
}
.el-row-3 {
  margin: 0px;
  padding: 5px 0;
  border-bottom: 1px solid #ddd;
}
.el-row-3 div:nth-child(2) {
  padding-left: 25px;
}
.el-row-3 div:nth-child(3),
.el-row-3 div:nth-child(4),
.el-row-3 div:nth-child(5) {
  line-height: 80px;
  text-align: center;
}
.paylist-box {
  background: #f3fbfe;
}
.el-row-3 div span {
  color: #666;
  font-size: 14px;
}
.total-price {
  float: left;
  text-align: right;
  font-size: 14px;
  padding: 10px;
}
.total-price span:nth-child(1) {
  color: #e8393c;
  margin-right: 5px;
}
.total-price span:nth-child(2) {
  color: #666;
  margin-right: 20px;
}
.total-price span:nth-child(3) {
  display: inline-block;
  color: #333;
  width: 100px;
}
.order-form {
  width: 100%;
  background: #f4f4f4;
  float: left;
  text-align: right;
  padding: 15px;
  margin-top: 20px;
}
.order-form div {
  padding: 5px;
}
.order-form div:nth-child(1) span:nth-child(1) {
  color: #666;
  font-size: 14px;
  padding-right: 50px;
}
.order-form div:nth-child(1) span:nth-child(2) {
  color: #e8393c;
  font-size: 18px;
  font-weight: 700;
}
.order-form div:nth-child(2) span:nth-child(1) {
  color: #666;
  font-size: 14px;
}
.submit-order {
  padding: 5px;
  float: right;
  right: 0;
  text-align: center;
}
.submit-order > span {
  display: inline-block;
  color: #fff;
  background: #e33a3d;
  border-radius: 4px;
  width: 130px;
  height: 38px;
  font-size: 18px;
  font-weight: 700;
  line-height: 38px;
  user-select: none;
  cursor: pointer;
}
.addsite {
  position: relative;
  box-sizing: border-box;
  width: 420px;
  height: 460px;
  position: fixed;
  top: 50%;
  left: 50%;
  background: #f8f8f8;
  margin-left: -210px;
  margin-top: -230px;
  z-index: 1000;
  border-radius: 4px;
  border: 1px solid #aaa;
  text-align: center;
  padding: 20px 0;
}
.addsite .demo-ruleForm {
  margin-top: 15px;
}
.addsite .demo-ruleForm .el-button {
  padding: 12px 22px;
}
.addsite .demo-ruleForm div {
  padding: 5px 0;
}
.el-cascader--medium {
  width: 260px;
}
.el-icon-close {
  position: absolute;
  top: 5px;
  right: 10px;
  padding: 10px;
}
.el-icon-close:hover {
  color: #409eff;
}
.moreSite {
  font-size: 14px;
  color: #666;
  margin-top: 5px;
  user-select: none;
  cursor: pointer;
  display: inline-block;
}
.el-icon-arrow-up,
.el-icon-arrow-down {
  color: #666;
  width: 0px;
  margin-left: -18px;
}
.el-row-2-span-v-for,
.el-row-2-span {
  display: inline-block;
  padding: 5px;
  user-select: none;
  cursor: pointer;
  color: #456;
}
.el-row-2-span-v-if:hover {
  background: #fff3f3;
}
.el-row-2-span-option {
  float: right;
  margin-right: 20px;
  user-select: none;
  cursor: pointer;
  line-height: 30px;
}

.el-row-2-span-option:hover::before {
  display: block;
  color: #a0dcff;
}
</style>